.logo {
	height: 64px;
	width: 63px;
	background-image: url('/assets/logo/logo.svg');
	left: 360px;
}

.dk-hero-image {
	background-image: url('/assets/background/hero.jpg');
}
