@import './palette';

.dk-select .mat-form-field-underline {
	width: 0;
}
.dk-select .mat-form-field-flex {
	background: none !important;
}

.dk-select mat-label {
	color: #0f7fbe;
}

.dk-mat-container {
	box-shadow: 0 0 16px rgba(black, 0.1);
	background: white;
	padding: 40px;
}

.dk-dt-header {
	background-color: #cfcfcf;
	padding: 0 25px;
	font-size: 12px;
	height: 88px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	.dk-flex {
		font-size: 10px;
	}
	.dk-preview {
		margin-left:23px
	}
}
.dk-dt-header .mat-form-field-appearance-fill .mat-form-field-flex {
	background-color: white !important;
	border-radius: 4px 0px 0 0 !important;
}

.dk-dt-header .mat-form-field-wrapper {
	top: 10px;
}

.dk-dt-header mat-icon {
	margin-top: 7px;
	background: white;
	height: 42px;
	border-bottom: 1px solid #989898;
	display: flex;
	align-items: center;
	color: #757575;
	width: 49px;
	justify-content: center;
	border-left: 1px solid #d7d7d7;
	cursor: pointer;
	transition: 99ms;
}

.dk-dt-header mat-icon:hover {
	border-bottom: 2px solid #000;
}

.dk-flex {
	display: flex !important;
	align-items: center;
}

.dk-dt-header mat-date-range-input {
	font-size: 15px;
	position: relative;
	bottom: 4px;
}

.dk-dt-header mat-datepicker-toggle {
	font-size: 12px;
	button {
		transform: scale(1.2);
	}
}

.dk-dt-header mat-select {
	font-size: 14px;
	position: relative;
	z-index: 2;
	bottom: 4px;
	.mat-select-arrow-wrapper {
		position: relative;
		top: 6px;
	}
}

.dk-dt-header input.mat-datepicker-input {
	font-size: 16px;
	bottom: 2px;
	position: relative;
}

.dk-dt-header {
	.dk-manage-profile-btn {
		margin-left: 12px;
		color: #0077ba;
		margin-top: 7px
	}
}

.dk-date-picker-container {
	.mat-date-range-input-container  {
		display: flex;
    align-items: center;
    justify-content: left;
    margin-left: auto;
    width: fit-content;
		border-bottom: solid 1px;
	}
}

mat-option {
	font-size: 16px !important;
}

dk-filter {
	.mat-form-field-wrapper {
		bottom: 10px;
    padding-bottom: 0;
	}
}

.borderless {
	border: 0 !important;
}

.bg-white {
	background: white;
}

dk-time-range-select {
	.mat-form-field-infix {
		width: auto !important;
	}
}

.md-drppicker .ranges ul li button.active {
	background-color: $cherry-red !important;
}

.md-drppicker th {
	color: #505050 !important;
}

.md-drppicker .ranges {
	height: 280px;
	display: flex;
	align-items: center;
}

.md-drppicker td.active {
	background-color: $cherry-red !important;
}
