@import '~@angular/material/theming';
@include mat-core();
@import 'styles/mixins';
@import 'styles/extensions';
@import 'styles/palette';
@import 'styles/data-table';
@import 'styles/brand/styles';
@import 'styles/mat-dk';
@import 'styles/vars';

$dash-king-ui-primary: mat-palette($mat-indigo);
$dash-king-ui-accent: mat-palette($mat-pink, A200, A100, A400);
$dash-king-ui-warn: mat-palette($mat-red);
$dash-king-ui-theme: mat-light-theme(
	(
		color: (
			primary: $dash-king-ui-primary,
			accent: $dash-king-ui-accent,
			warn: $dash-king-ui-warn
		)
	)
);

@include angular-material-theme($dash-king-ui-theme);

@font-face {
	font-family: BlockBerthold;
	src: url('/assets/fonts/block-berthold-regular.ttf') format('truetype');
}

html,
body {
	position: relative;
	display: block;
	height: auto;
	width: 100vw;
	margin: 0;
	padding: 0;
	overflow-x: hidden;
	overflow-y: auto;
}

body {
	font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.clickable {
	cursor: pointer;
}

.hidden {
	display: none;
}

.d-inline {
	display: inline !important;
}

.dk-error-dialog-container {
	width: 312px;
	height: 416px;
	background: #fbf5ee;
	border-radius: 6px;

	.mat-dialog-container {
		background: #fbf5ee !important;
	}
}
.w-100 {
	width: 100%;
}
.uppercase {
	text-transform: uppercase;
}
.mt-5 {
	margin-top: 2em;
}
.pl-0 {
	padding-left: 0 !important;
}
.font-weight-bold {
	font-weight: bold !important;
}
.font-weight-light {
	font-weight: 100;
}
.zoomed-out {
	zoom: 80%;
}

.export-label {
	border: 0 !important;
	color: #0077ba;
}

.picker {
	background: #e0e0e0;
	justify-content: revert !important;
}
.picker > .mat-form-field > .mat-form-field-wrapper {
	top: 10px;
	margin-left: 14px;
	.mat-form-field-flex {
		background: white;
	}
}

.picker > .today-btn {
	margin-left: 32px;
}
.picker > button {
	position: relative;
	top: 3px;
	color: #0077ba;
}

.mat-ink-bar {
	height: 3px !important;
	background-color: #505050 !important;
}
.mat-tab-label-active {
	opacity: revert !important;
	color: #505050;
	font-weight: bolder;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
	background: rgba(0, 186, 86, 0.2);
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
	background-color: #00ba56;
}
.mat-expansion-panel-body {
	padding: 0 !important;
}
dk-page-header {
	#overview-picker {
		.mat-form-field-flex {
			padding-top: 0;
		}
	}
	.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
		position: relative;
		top: 5px;
	}
}
.text-right {
	text-align: right !important;
}
.text-left {
	text-align: left !important;
}
.row {
	display: flex;
}

dk-dtt-export .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
	border-color: $cherry-red;
}
dk-dtt-export .mat-radio-button.mat-accent .mat-radio-inner-circle {
	background-color: $cherry-red;
}
dk-dtt-export .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
	background-color: $cherry-red;
}

dk-accounting {
	.mat-tab-body-wrapper {
		min-height: 80vh;
	}
}
.mat-select-panel {
	max-height: 384px !important;
}
