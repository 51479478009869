@import 'palette';

.tl {
	text-align: left;
}

table.dki-data-table {
	border-collapse: collapse;
	text-align: right;
	width: 100%;

	thead {
		color: $gray1;

		tr {
			border-top: 1px solid $gray1;
			border-bottom: 1px solid $gray1;
		}
	}

	tbody {
		tr {
			border-top: 1px solid $gray2;
			border-bottom: 1px solid $gray2;
		}

		tr.child-row {
			background-color: $gray3;
			text-align: right;

			td {
				font-weight: normal;
			}
		}
	}

	tfoot {
		background-color: $sand;
	}

	th,
	td {
		font-weight: 500;
		padding: 0.4rem 0.8rem;
	}
}
